import React from "react";
import Typewriter from "typewriter-effect";
import headerImg from "../assets/img/headerimg1.png";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store"; // Adjust the path according to your store setup
import useScrollToTop from "./useScrollToTop";

const Header: React.FC = () => {
  useScrollToTop();
  const state = useSelector((state: RootState) => state);
  const userProfile = state.Profile[0];
  console.log(state.Profile);

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.6,
        }}
        viewport={{
          amount: "all",
        }}
        className="grid grid-cols-1 md:grid-cols-2 pt-[9vh] rounded-2xl text-black lg:w-[96vw] w-[100vw] lg:ml-5 ml-0 h-[75vh] mt-[15vh] max-[450px]:mt-[13vh] pl-[2.3%] pr-[3.7%] bg-gradient-to-r from-[#3259ac] to-[#0c3ca3]"
      >
        <div className="header-text md:pt-6 pt-[22vh] md:w-[70vw] text-white">
          <h1 className="lg:text-[1.5rem] font-semibold">
            Welcome
            <span className="text-white ml-2 italic">{userProfile?.name}</span>
          </h1>
          <h1 className="lg:text-[2.3rem] text-[1.5rem] mt-2 font-bold md:w-[60vw]">
            <Typewriter
              options={{
                strings: [
                  "Find Your Ideal Home.",
                  "Discover Amazing Properties.",
                  "Secure Your Investment.",
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 30,
                delay: 70,
              }}
            />
          </h1>
          <p className="mt-4 md:pr-[25vw] font-semibold text-[1rem] md:text-[1.1rem]">
            Your premier destination for real estate services. Explore
            top-quality homes, local conveniences, and outstanding support.
          </p>

          <div>
            <form className="md:mt-10 mt-9">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email to get started"
                className="md:py-[1.2rem] py-[0.8rem] rounded-3xl md:px-8 px-4"
              />
              <button
                type="submit"
                className="md:py-[1.3rem] bg-blue-900 hover:bg-blue-600 transition mt-[-0.1rem] md:mt-0 py-[0.9rem] absolute md:ml-[-2.1rem] ml-[-2rem] text-white px-5 lg:px-8 rounded-3xl"
              >
                Book Tour
              </button>
            </form>
          </div>
        </div>
        <div className="header-img md:w-[40%]">
          <motion.img
            initial={{
              translateX: 100,
            }}
            animate={{
              translateX: 0,
            }}
            transition={{
              duration: 0.6,
            }}
            viewport={{
              amount: "all",
            }}
            src={headerImg}
            className="md:w-[50%] w-[78%] absolute max-[450px]:top-0 mt-[-6vh] ml-[22vw] md:mt-[-8vh] md:ml-[4vw]"
            alt="Header"
          />
        </div>
      </motion.div>
    </>
  );
};

export default Header;

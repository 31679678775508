import React, { useState } from "react";
import PropertyCard from "../components/PropertyColumn";
import FilterAside from "../components/FilterAside";
import { IoHome } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MdMenu, MdCancel } from "react-icons/md";
import useScrollToTop from "../components/useScrollToTop";
import BottomNavbar from "../components/BottomNavbar";
import { RootState } from "../redux/Store";
import { useSelector } from "react-redux";

// Define the Property type
interface Property {
  id: number;
  type: string;
  name: string;
  img: string;
  location: string;
  price: string;
  condition: string;
}

const AllProperties: React.FC = () => {
  useScrollToTop();
  const [filteredType, setFilteredType] = useState<string>("");
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const State = useSelector((state: RootState) => state);
  const { allProperties: properties } = State.AllProperties;

  // Handle filter selection
  const handleFilterChange = (filterValue: string) => {
    setFilteredType(filterValue);
  };

  // Toggle sidebar
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  // Filter properties based on type and condition
  const filterProperties = (property: Property) => {
    if (!filteredType) {
      return true; // If no filter is applied, show all properties
    } else {
      const [type, condition] = filteredType.split(",");
      if (type === property.type) {
        if (condition === "All") {
          return true;
        } else {
          return property.condition.toLowerCase() === condition.toLowerCase();
        }
      }
      return false;
    }
  };

  // Get the filtered properties
  const filteredProperties = properties.filter((property: any) =>
    filterProperties(property)
  );

  return (
    <>
      <div className="flex">
        {/* Sidebar Toggle Button */}
        <div className="md:hidden absolute z-50 ml-2 mt-2 text-2xl cursor-pointer">
          {sidebarOpen ? (
            <MdCancel className="text-white" onClick={toggleSidebar} />
          ) : (
            <MdMenu onClick={toggleSidebar} />
          )}
        </div>

        {/* Filter Aside */}
        <div className={`md:block`}>
          <FilterAside
            sidebarOpen={sidebarOpen}
            onFilterChange={handleFilterChange}
          />
        </div>

        {/* Main Properties Section */}
        <div className="flex-1 pt-4 lg:py-6 pl-3 pr-8 pb-[7em] lg:pb-8">
          <div className="lg:flex block justify-between items-center mb-4">
            <h1 className="text-2xl font-bold flex gap-2 ml-[20%] lg:ml-0 items-center">
              All Properties{" "}
              <IoHome className="text-blue-600 hover:text-blue-800 mr-1 animate-pulse" />
            </h1>
            <Link to={"/"}>
              <button className="py-2 px-4 max-[450px]:hidden bg-blue-600 hover:bg-blue-800 text-white rounded-lg">
                Home
              </button>
            </Link>
          </div>

          {/* Properties List */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 ml-[5%] lg:ml-[18%] lg:w-[85%]">
            {filteredProperties.map((property) => (
              <PropertyCard
                id={property._id}
                key={property._id}
                img={property.images}
                name={property.title}
                location={property.location}
                price={property.price}
                condition={property.condition}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Bottom Navigation */}
      <BottomNavbar current={"all-properties"} />
    </>
  );
};

export default AllProperties;

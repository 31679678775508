// src/redux/slices/PropertySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the property
interface Property {
  id: string; // Assuming id is a number; adjust as needed
  // Add other properties relevant to your application
  name?: string; // Example property
  location?: string; // Example property
  // Add more fields as necessary
}

// Define the initial state interface
interface PropertyState {
  favouriteProperties: Property[];
}

// Create the property slice
const PropertySlice = createSlice({
  name: "Properties",
  initialState: {
    favouriteProperties: [],
  } as PropertyState,
  reducers: {
    addProperty: (state, action: PayloadAction<Property>) => {
      state.favouriteProperties.push(action.payload);
    },
    removeProperty: (state, action: PayloadAction<string>) => {
      // Assuming action.payload is the id of the property
      const itemId = action.payload;
      state.favouriteProperties = state.favouriteProperties.filter(
        (item) => item.id !== itemId
      );
    },
  },
});

// Export actions
export const { addProperty, removeProperty } = PropertySlice.actions;

// Export the reducer
export default PropertySlice.reducer;

// src/redux/ProfileSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the profile
interface Profile {
  id: string; // Assuming each profile has an id
  name: string; // Add other fields as needed
  email?: string; // Optional field
  image?: string;
  token?: string;
  role?: string;
  // Add more fields as necessary
}

// Define a type for the initial state
type ProfileState = Profile[]; // An array of Profile objects

const ProfileSlice = createSlice({
  name: "Profile",
  initialState: [] as ProfileState, // Specify the type for initial state
  reducers: {
    addProfile: (state, action: PayloadAction<Profile>) => {
      state.push(action.payload); // Action payload must be a Profile
    },
    removeProfile: () => {
      return []; // Resetting state to an empty array
    },
  },
});

export const { addProfile, removeProfile } = ProfileSlice.actions;
export default ProfileSlice.reducer;

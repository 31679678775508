// src/redux/codeSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the code slice state
interface CodeState {
  entryCode: string | null; // The entry code can be a string or null
  status: "idle" | "loading" | "succeeded" | "failed"; // Status can be one of these four values
  error: string | null; // The error can be a string or null
}

// Async thunk to fetch the code from the server
export const fetchCode = createAsyncThunk<string, void>(
  "code/fetchCode",
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_ENTRYCODE}`);
    let data = response.data;
    let code = data.secretCode;

    return code;
  }
);

const initialState: CodeState = {
  entryCode: null,
  status: "idle",
  error: null,
};

const codeSlice = createSlice({
  name: "code",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCode.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = "succeeded";
        state.entryCode = action.payload;
      })
      .addCase(fetchCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default codeSlice.reducer;

// src/components/Navbar.tsx
import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import logo1 from "../assets/img/dbestlogo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Define types for the Redux state
interface RootState {
  Profile: Array<{
    role: string;
  }>;
}

// Navbar component
const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track mobile menu toggle
  const State = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  const userProfile = State.Profile[0];
  console.log(userProfile);

  const dashboardNavigate = () => {
    if (userProfile.role === "admin") {
      navigate("/admin-dashboard");
    } else if (userProfile.role === "resident") {
      navigate("/resident-dashboard");
    } else if (userProfile.role === "staff") {
      navigate("/staff-dashboard");
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <div className="flex justify-between pr-[5rem] lg:pl-5 pl-2 py-[0.3rem] lg:py-[1rem] bg-[#d3e1ff] rounded-lg fixed top-0 lg:w-[96vw] w-[100vw] lg:ml-5 z-50">
      {/* Logo and Title */}
      <div className="lg:z-50">
        <h1 className="text-[1.7rem] text-black max-[900px]:hidden font-bold">
          D'Best Shelters
        </h1>
        <Link to={"/"}>
          <img
            src={logo1}
            alt="Opulent Logo"
            className="lg:hidden w-[4.3rem] mt-[0.2rem]"
          />
        </Link>
      </div>

      {/* Mobile navbar toggle button */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="lg:hidden text-black nav-toggle mt-[0.7rem] mr-[-2.8rem] cursor-pointer"
      >
        <GiHamburgerMenu size={30} />
      </div>

      {/* Navbar links */}
      <ul
        className={`${
          isOpen ? "block" : "hidden"
        } lg:flex gap-[3rem] navbar lg:ml-[25%] bg-white lg:bg-transparent p-4  max-[450px]:h-[60vh] lg:p-0 absolute lg:relative top-[4rem] lg:top-0 left-0 w-full lg:pt-1 lg:w-auto z-40 lg:z-0`}
      >
        <li className="group relative max-[450px]:mb-1 max-[450px]:py-3">
          <Link to={"/"} className="hover:text-blue-700">
            Home
          </Link>
        </li>

        <li className="group relative max-[450px]:mb-1 max-[450px]:py-3">
          <Link to={"/all-properties"} className="hover:text-blue-700">
            All Properties
          </Link>
        </li>

        {/* Dropdown menu for Categories */}
        <li className="group relative max-[450px]:mb-1 max-[450px]:py-3">
          <span className="hover:text-blue-700">Categories</span>
          <ul className="dropdown-content hidden group-hover:block absolute bg-blue-100 shadow-md mt-1 rounded-md min-w-[160px]">
            <li className="p-2 hover:bg-blue-200">
              <a href="/">Link1</a>
            </li>
            <li className="p-2 hover:bg-blue-200">
              <a href="/">Link2</a>
            </li>
            <li className="p-2 hover:bg-blue-200">
              <a href="/">Link3</a>
            </li>
            <li className="p-2 hover:bg-blue-200">
              <a href="/">Link4</a>
            </li>
          </ul>
        </li>

        <li className="group relative max-[450px]:mb-5 max-[450px]:py-3">
          <Link to={"/"} className="hover:text-blue-700">
            About Us
          </Link>
        </li>

        {/* Login/Signup or Dashboard */}
        <li>
          {!userProfile ? (
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-4">
              <li>
                <Link
                  to={"/login"}
                  className="px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800"
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to={"/signup"}
                  className="px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800"
                >
                  Register
                </Link>
              </li>
            </ul>
          ) : (
            <button
              onClick={dashboardNavigate}
              className="px-4 py-2 bg-blue-800 md:mt-[-0.3rem] text-white rounded hover:bg-blue-900"
            >
              Dashboard
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

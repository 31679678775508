// src/redux/CurrentPropertySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the current property
interface Property {
  id: number; // Adjust according to your property structure
  name: string; // Example property; include all necessary fields
  // Add other fields as needed
}

// Define the initial state type
interface CurrentPropertyState {
  currentProperty: Property[];
}

const initialState: CurrentPropertyState = {
  currentProperty: [],
};

const CurrentPropertySlice = createSlice({
  name: "CurrentProperty",
  initialState,
  reducers: {
    addCurrentProperty: (state, action: PayloadAction<Property>) => {
      state.currentProperty.push(action.payload);
    },
    removeCurrentProperty: (state) => {
      state.currentProperty = [];
    },
  },
});

export const { addCurrentProperty, removeCurrentProperty } =
  CurrentPropertySlice.actions;
export default CurrentPropertySlice.reducer;

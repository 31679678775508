import React from "react";
import { motion } from "framer-motion";
import { FiCheckCircle } from "react-icons/fi";
import personLogo1 from "../assets/img/dbestperson2.jpeg";

const AboutUs: React.FC = () => {
  return (
    <div>
      {/* About Section */}
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="md:max-w-[90%] max-w-[100%] mx-auto pt-12 pb-10 md:pb-20 mt-16 md:px-9 bg-white rounded-xl shadow-md"
      >
        <h2 className="text-2xl md:text-3xl border-b-2 border-gray-400 w-28 md:w-[8.7rem] font-semibold mx-auto">
          <span className="text-blue-600">About</span> Us
        </h2>

        <div className="md:flex mt-10 md:mt-16 gap-16">
          <div className="about-img">
            <img
              src={personLogo1}
              alt="AboutUs"
              className="w-64 md:w-[25rem] rounded-md shadow-xl hover:shadow-2xl mx-auto md:mt-[-2rem]"
            />
          </div>
          <div className="about-text w-80 md:w-96 mt-8 md:mt-0 mx-auto pl-0 md:pl-2">
            <h1 className="text-lg md:text-2xl max-[450px]:pl-1 font-semibold">
              We Are Your Reliable and{" "}
              <span className="text-blue-600">Premier Real Estate</span> Partner
            </h1>
            <p className="mt-3 max-[450px]:pl-1">
              We take pride in delivering exceptional real estate services,
              ensuring our clients' satisfaction and maintaining transparency
              throughout every transaction.
            </p>

            <ul className="pt-4 space-y-4">
              <motion.li
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="flex gap-2 items-center py-2 px-4 border border-gray-300 hover:shadow-md"
              >
                <FiCheckCircle className="text-blue-600 bg-blue-100 rounded-full" />{" "}
                Professional and Skilled Agents.
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="flex gap-2 items-center py-2 px-4 border border-gray-300 hover:shadow-md"
              >
                <FiCheckCircle className="text-blue-600 bg-blue-100 rounded-full" />{" "}
                In-depth Market Insights.
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="flex gap-2 items-center py-2 px-4 border border-gray-300 hover:shadow-md"
              >
                <FiCheckCircle className="text-blue-600 bg-blue-100 rounded-full" />{" "}
                Honest and Equitable Transactions.
              </motion.li>
            </ul>
          </div>
        </div>
      </motion.div>
      {/* End About Section */}
    </div>
  );
};

export default AboutUs;

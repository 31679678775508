import { Client, Account, Databases, Storage } from "appwrite";

const client = new Client();
client.setProject("67121c920017433a1a74");

const account = new Account(client);
const database = new Databases(client);
const storage = new Storage(client);

export { client, account, database, storage };

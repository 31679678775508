// src/components/Loader.tsx
import React from "react";
import loader from "../assets/img/dbestlogo.jpeg"; // Importing the loader image

// Loader component
const Loader: React.FC = () => {
  return (
    <div className="h-[100vh] flex items-center justify-center bg-gray-100 w-[100vw] mx-auto">
      {/* Full-screen container with gray background */}
      <div className="py-10">
        {/* Padding for content */}
        <span>
          <img
            className="loading-img animate-pulse mt-[-1rem] max-[380px]:mt-[-4.5rem] lg:mt-[-4rem] mx-auto w-[200px] my-auto md:w-[300px]"
            src={loader} // Using the imported loader image
            alt="Loading..." // Alt text for accessibility
          />
        </span>
      </div>
    </div>
  );
};

export default Loader;

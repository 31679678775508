// src/components/LiveWithUs.tsx
import React from "react";
import { motion } from "framer-motion";
import { FaHome, FaShoppingCart, FaCheckCircle } from "react-icons/fa";

const LiveWithUs: React.FC = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="mt-10 py-20"
      >
        <h2 className="text-xl md:text-3xl border-b-2 border-gray-400 w-[19.2rem] md:w-[27.5rem] pl-2 font-semibold mx-auto">
          Why You Should Live With Us
        </h2>
        <h4 className="text-center mt-4">
          We're Here To Satisfy All Your Housing Needs
        </h4>

        <div className="grid grid-cols-1 md:grid-cols-3 w-full text-center mt-10 gap-4 md:gap-8">
          <div className="w-72 mx-auto md:mb-0 transition hover:border hover:border-black p-3 hover:rounded-lg">
            <FaHome className="mx-auto text-5xl text-blue-600 mb-3" />
            <h2 className="font-semibold text-lg">Diverse Property Options</h2>
            <p className="text-sm mt-2">
              Whether you're looking for an apartment, a house, or land, we have
              something for everyone.
            </p>
          </div>

          <div className="w-72 mx-auto md:mb-0 transition hover:border hover:border-black p-3 hover:rounded-lg">
            <FaShoppingCart className="mx-auto text-5xl text-blue-600 mb-3" />
            <h2 className="font-semibold text-lg">
              Flexible Rental and Purchase Plans
            </h2>
            <p className="text-sm mt-2">
              Enjoy the freedom to rent or buy your dream home with flexible
              options tailored for you.
            </p>
          </div>

          <div className="w-72 mx-auto transition hover:border hover:border-black p-3 hover:rounded-lg">
            <FaCheckCircle className="mx-auto text-5xl text-blue-600 mb-3" />
            <h2 className="font-semibold text-lg">Highly Rated by Families</h2>
            <p className="text-sm mt-2">
              Our properties are trusted by families who seek comfort, safety,
              and quality living.
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LiveWithUs;

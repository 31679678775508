// src/components/PropertyColumn.tsx
import React from "react";
import { FaLocationDot, FaNairaSign } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// Define the types for the props
interface PropertyColumnProps {
  id: string | number;
  name: string;
  location: string;
  img: string[];
  condition: string;
  price: string | number; // Assuming price can be either a string or a number
}

// PropertyColumn component
const PropertyColumn: React.FC<PropertyColumnProps> = ({
  id,
  name,
  location,
  img,
  condition,
  price,
}) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        translateY: 100,
      }}
      whileInView={{
        opacity: 1,
        translateY: 0,
      }}
      transition={{
        duration: 1,
      }}
      className="text-left rounded-lg shadow-lg shadow-gray-400 mx-auto lg:w-[70%] w-[86%] py-3 px-1 hover:shadow-xl hover:shadow-gray-500"
    >
      <div>
        <img
          src={img[0]}
          alt={name} // Use the name for alt text for better accessibility
          className="w-full h-[13rem] object-cover rounded"
        />
      </div>
      <div className="flex justify-between mt-3 px-3">
        <h3>{name}</h3>
        <p className="text-blue-700 text-xs font-semibold">For {condition}</p>
      </div>
      <h3 className="flex gap-1 ml-2 mt-1 text-blue-500 text-sm items-center">
        <FaLocationDot /> {location}
      </h3>
      <div className="flex justify-between mt-3 px-3 items-center">
        <div className="text-sm">
          <Link to={`/property-info?id=${id}`}>
            <button className="bg-blue-700 hover:bg-blue-800 text-white py-2 px-3 text-[0.8rem] rounded-lg">
              More Info
            </button>
          </Link>
        </div>

        <div>
          <h3 className="flex gap-1 items-center text-sm">
            <FaNairaSign /> {price}
          </h3>
        </div>
      </div>
    </motion.div>
  );
};

export default PropertyColumn;

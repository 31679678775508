// src/components/HowItWorks.tsx
import React from "react";
import { motion } from "framer-motion";
import { FaSearch, FaCalendarCheck, FaMoneyBillWave } from "react-icons/fa";

const HowItWorks: React.FC = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="mt-20 py-20"
      >
        <h2 className="text-2xl md:text-3xl border-b-2 border-gray-400 w-[9.7rem] md:w-[12rem] font-semibold mx-auto">
          How It <span className="text-blue-600">Works</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 w-full text-center mt-10 gap-5 md:gap-8">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="w-72 mx-auto"
          >
            <FaSearch className="mx-auto text-5xl text-blue-600" />
            <h2 className="font-semibold text-lg mt-4">
              1. Search through and select a house of your choice
            </h2>
            <p className="text-sm mt-2">
              Browse our extensive listings to find a home that fits your needs
              and preferences.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="w-72 mx-auto"
          >
            <FaCalendarCheck className="mx-auto text-5xl text-blue-600" />
            <h2 className="font-semibold text-lg mt-4">
              2. Book A Physical Tour With One Of Our Reps
            </h2>
            <p className="text-sm mt-2">
              Schedule a visit with one of our representatives to see the
              property in person.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="w-72 mx-auto"
          >
            <FaMoneyBillWave className="mx-auto text-5xl text-blue-600" />
            <h2 className="font-semibold text-lg mt-4">
              3. Make Payment And Get Your Dream Home.
            </h2>
            <p className="text-sm mt-2">
              Finalize the paperwork and complete your payment to secure your
              new home.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default HowItWorks;

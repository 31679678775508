import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaBuilding, FaEnvelope, FaUser } from "react-icons/fa";

interface BottomNavbarProps {
  current: string;
}

const BottomNavbar: React.FC<BottomNavbarProps> = ({ current }) => {
  return (
    <div className="fixed lg:hidden bottom-0 shadow-lg left-0 right-0 bg-white text-[#2563EB] border border-gray-300">
      <div className="flex justify-around py-2">
        <Link
          to="/"
          className={`flex flex-col items-center px-3 py-1 ${
            current === "home" ? "bg-blue-100" : "bg-white"
          }`}
        >
          <FaHome className="text-lg" />
          <span className="text-xs mt-1">Home</span>
        </Link>
        <Link
          to="/all-properties"
          className={`flex flex-col items-center px-3 py-1 ${
            current === "all-properties" ? "bg-blue-100" : "bg-white"
          }`}
        >
          <FaBuilding className="text-lg" />
          <span className="text-xs mt-1">Properties</span>
        </Link>
        <Link
          to="/contact"
          className={`flex flex-col items-center px-3 py-1 ${
            current === "contact" ? "bg-blue-100" : "bg-white"
          }`}
        >
          <FaEnvelope className="text-lg" />
          <span className="text-xs mt-1">Contact</span>
        </Link>
        <Link
          to="/admin-dashboard"
          className={`flex flex-col items-center px-3 py-1 ${
            current === "dashboard" ? "bg-blue-100" : "bg-white"
          }`}
        >
          <FaUser className="text-lg" />
          <span className="text-xs mt-1">Profile</span>
        </Link>
      </div>
    </div>
  );
};

export default BottomNavbar;

import React, { useState, useEffect } from "react";
import { FaNairaSign, FaLocationDot, FaBed, FaBath } from "react-icons/fa6";
import { FaRocket } from "react-icons/fa";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import PropertyRow from "../components/PropertyRow";
import useScrollToTop from "../components/useScrollToTop";
import { useSearchParams } from "react-router-dom";
import { database } from "../appWrite";

const PropertyInfo: React.FC = () => {
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [property, setProperty] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const [zoomedImageIndex, setZoomedImageIndex] = useState<number>(0);

  const images: string[] = [
    /* Your default images if any */
  ];

  const handleImageClick = (index: number): void => {
    setZoomedImageIndex(index);
    setZoomedImage(images[index]);
  };

  const handleCloseZoom = (): void => {
    setZoomedImage(null);
  };

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await database.getDocument(
          "67121ea200295b186e8d",
          "67121ee20011e15470aa",
          id!
        );
        setProperty(response);
        console.log(response);

        setLoading(false);
      } catch (err) {
        setError("Failed to fetch property details.");
        setLoading(false);
      }
    };

    if (id) {
      fetchProperty();
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Navbar />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
        className="w-full min-h-screen bg-slate-50"
      >
        <div className="mt-20 pt-4 px-5 md:px-20">
          <h3 className="font-bold text-xl md:text-2xl">{property?.name}</h3>
          <div className="md:flex mt-4">
            <div className="md:w-2/3 w-full">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }}
              >
                {property?.images.map((img: string, index: number) => (
                  <SwiperSlide key={index}>
                    <img
                      src={img}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-64 md:h-[70vh] rounded-lg shadow-lg object-cover cursor-pointer"
                      onClick={() => handleImageClick(index)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="md:w-1/3 w-full mt-6 md:mt-0 md:ml-6 bg-white p-4 rounded-2xl shadow-lg">
              <ul className="grid grid-cols-2 gap-2 items-center">
                <li className="flex font-semibold text-lg gap-1 items-center">
                  Price: <FaNairaSign /> {property?.price}
                </li>
                <li className="text-gray-600 ml-4 text-sm">
                  Posted: {property?.created}
                </li>
                <li className="flex gap-1 items-center w-[140%] text-gray-700 text-sm">
                  <FaLocationDot className="text-gray-700 animate-pulse" />{" "}
                  {property?.location}
                </li>
                <li className="flex gap-3 ml-[4rem] items-center text-gray-700 text-sm">
                  <span className="flex items-center gap-1">
                    <FaBed /> {property?.bedrooms}
                  </span>
                  <span className="flex items-center gap-1">
                    <FaBath /> {property?.bathrooms}
                  </span>
                </li>
              </ul>
              {property.features ? (
                <>
                  <p>{property.features}</p>
                </>
              ) : (
                <>
                  <ul className="mt-6 text-sm space-y-2">
                    <li>- All rooms ensuite</li>
                    <li>- Fitted and furnished kitchen</li>
                    <li>- Walk-in shower</li>
                    <li>- Walk-in closet</li>
                    <li>- Bath tub</li>
                    <li>- 24/7 light</li>
                    <li>- Gated and secure estate</li>
                  </ul>
                </>
              )}

              <div className="mt-8">
                <h3 className="mb-3 font-semibold text-lg">
                  Book Physical Tour
                </h3>
                <form>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="First name"
                    className="border border-gray-400 mb-2 py-2 px-3 rounded w-full"
                  />
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Last name"
                    className="border border-gray-400 mb-2 py-2 px-3 rounded w-full"
                  />
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Phone number"
                    className="border border-gray-400 mb-2 py-2 px-3 rounded w-full"
                  />
                  <input
                    type="text"
                    name="interested property"
                    id="interested-property"
                    value={id!}
                    className="hidden"
                  />
                  <button
                    className="py-2 px-3 flex justify-center items-center gap-2 mt-2 bg-blue-700 text-white rounded-lg hover:bg-blue-800 w-full"
                    type="submit"
                  >
                    Book Tour <FaRocket className="animate-bounce" />
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* Featured */}
          <PropertyRow />
        </div>

        {/* Modal for zoomed image */}
        {zoomedImage !== null && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex items-center justify-center z-50"
            onClick={handleCloseZoom}
          >
            <div className="lg:w-[59vw] w-[90vw] h-[90vh] mt-[-90vh] lg:h-[70vh] lg:mt-[-50rem]">
              <Swiper
                modules={[Pagination, Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                initialSlide={zoomedImageIndex}
                pagination={{ clickable: true }}
                navigation
                onSlideChange={(swiper: any) =>
                  setZoomedImageIndex(swiper.activeIndex)
                }
              >
                {property?.images.map((img: string, index: number) => (
                  <SwiperSlide key={index}>
                    <img
                      src={img}
                      alt={`Zoomed Slide ${index + 1}`}
                      className="w-full object-contain rounded-lg shadow-lg"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default PropertyInfo;

import React, { useState } from "react";
import login from "../assets/img/pexels-vika-glitter-392079-1648776.jpg";
import desktopLogin from "../assets/img/pexels-fotoaibe-1643383.jpg";
import { motion } from "framer-motion";
import cozyez from "../assets/img/dbestlogo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addProfile } from "../redux/slices/ProfileSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { account, database } from "../appWrite"; // Import database from Appwrite
import { Query } from "appwrite";
import useScrollToTop from "../components/useScrollToTop";

// Define the shape of the form data
interface FormData {
  userEmail: string;
  userPassword: string;
}

const Login: React.FC = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    userEmail: "",
    userPassword: "",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Check if a session already exists
      let session;
      try {
        session = await account.getSession("current");
        account.deleteSessions();
      } catch (err) {
        // No active session found, so we proceed to create a new one
        session = await account.createEmailPasswordSession(
          formData.userEmail,
          formData.userPassword
        );
      }

      // Get the user information
      const user = await account.get();
      console.log(user);

      // Fetch user attributes from the database
      const userAttributes = await database.listDocuments(
        "67121ea200295b186e8d", // Replace with your actual database ID
        "67121ec9000c0778952e", // Replace with your actual collection ID
        [Query.equal("userId", user.$id)]
      );

      // Check if any user attributes were found
      const userRole =
        userAttributes.documents.length > 0
          ? userAttributes.documents[0].role
          : null;

      // Dispatch the profile information including the role
      dispatch(
        addProfile({
          id: user.$id,
          name: user.name,
          image: user.prefs?.image || "", // Adjust this if your user object differs
          token: session.$id, // This is just an example, adjust based on your needs
          role: userRole, // Include the role
        })
      );

      // Navigate to the home page
      navigate("/");
    } catch (error: any) {
      const errorMsg = error.message || "An error occurred";
      showAlert(errorMsg);
    }
  };

  function showAlert(msg: string) {
    const errDiv = document.querySelector(".error-div") as HTMLElement;

    if (errDiv) {
      errDiv.textContent = msg;
      errDiv.classList.toggle("hidden");

      setTimeout(() => {
        errDiv.classList.toggle("hidden");
      }, 2000);
    }
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 2,
      }}
      viewport={{
        amount: "all",
      }}
      className="relative"
    >
      {/* Background images */}
      <img
        src={login}
        className="absolute w-[100vw] h-[100vh] object-cover lg:hidden"
        alt=""
      />
      <img
        src={desktopLogin}
        className="absolute w-[100vw] h-[100vh] object-cover max-[450px]:hidden"
        alt=""
      />

      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-60 z-10 h-[100vh]"></div>

      <div className="text-white relative z-20">
        <img
          src={cozyez}
          className="w-[4.6rem] md:w-[8rem] absolute z-20 ml-[-2.4vw] md:ml-0"
          alt="Cozyez Logo"
        />

        <button
          onClick={reloadPage}
          className="ml-[80%] bg-blue-600 hover:bg-blue-800 py-1 px-2 rounded absolute lg:ml-[84%] mt-[4vh] md:mt-[6vh]"
        >
          Back
        </button>

        <h1 className="text-center text-[1.5rem] md:text-[1.8rem] font-bold pt-[22vh] md:pt-[18vh]">
          Login
        </h1>

        {!isFormVisible ? (
          <div className="mt-12 md:mt-20">
            <Link to={"/"}>
              <button
                className="w-[80%] md:w-[40%] ml-[10%] md:ml-[30vw] py-2 md:py-4 lg:py-3 md:text-[1.2rem] mt-5 bg-blue-600 text-white font-semibold rounded transition hover:bg-blue-800 md:block"
                type="submit"
              >
                Guest Login
              </button>
            </Link>
            <button
              className="w-[80%] md:w-[40%] ml-[10%] md:ml-[30vw] py-2 md:py-4 lg:py-3 md:text-[1.2rem] mt-5 bg-blue-600 text-white font-semibold rounded transition hover:bg-blue-800 md:block"
              type="submit"
              onClick={() => setIsFormVisible(true)}
            >
              User Login
            </button>
          </div>
        ) : (
          <motion.div
            initial={{
              translateX: -100,
            }}
            animate={{
              translateX: 0,
            }}
            transition={{
              duration: 0.6,
            }}
            viewport={{
              amount: "all",
            }}
            className="p-6 max-[450px]:mt-[3.6rem] lg:ml-[4.4rem] md:mt-8 rounded-lg shadow-xl"
          >
            <div className="error-div hidden mb-4 bg-red-500 text-white w-[63%] px-6 mx-auto py-2 rounded-lg"></div>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="userEmail"
                id="userEmail"
                placeholder="Email address"
                value={formData.userEmail}
                onChange={handleChange}
                className="border border-gray-100 bg-gray-900 placeholder:text-white py-2 px-4 w-full md:w-[60vw] rounded mb-3 md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]"
              />
              <div className="relative md:w-[60vw] md:ml-[18vw] lg:w-[40vw] lg:ml-[22vw]">
                <input
                  type={showPassword ? "text" : "password"}
                  name="userPassword"
                  id="userPassword"
                  placeholder="Password"
                  value={formData.userPassword}
                  onChange={handleChange}
                  className="border border-gray-100 bg-gray-900 placeholder:text-white py-2 px-4 w-full rounded mb-3"
                />
                <button
                  type="button"
                  className="absolute right-3 top-3 text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <p className="mt-2 flex gap-2 ml-1 md:ml-[18vw] lg:ml-[22vw]">
                Don't have an account?{" "}
                <Link className="font-semibold text-blue-600" to="/signup">
                  Sign up
                </Link>
              </p>
              <button
                className="w-[80%] md:w-[60vw] ml-[10%] md:ml-[18vw] py-2 mt-5 bg-blue-600 text-white font-semibold rounded transition hover:bg-blue-800 lg:w-[40vw] lg:ml-[22vw]"
                type="submit"
              >
                Submit
              </button>
            </form>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default Login;
